var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('loading-overlay',{attrs:{"active":_vm.isLoading,"is-full-page":true,"loader":"bars"}}),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12","lg":"5"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.classificationMachine'),"horizontal":{label:'col-sm-12 col-lg-auto',input:'col-sm-12 col-lg-6'},"addLabelClasses":"text-right","value":_vm.ClassificationTypeSelect,"options":_vm.ClassificationListFormatted},on:{"change":function($event){return _vm.ClassificationList($event)}}})],1),_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12","lg":"7"}},[_c('div',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: _vm.$t('label.typeMachine'),
                    placement: 'top-end'
                 }),expression:"{\n                    content: $t('label.typeMachine'),\n                    placement: 'top-end'\n                 }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.toggleAdd}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)])],1),_c('CRow',{staticClass:"mb-3"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedItems,"fields":_vm.fields,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"sorter":"","noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"pagination":"","column-filter":"","hover":""},scopedSlots:_vm._u([{key:"Nro",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.Nro)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"actions",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                    content: _vm.$t('label.edit')+' '+_vm.$t('label.typeMachine'),
                                    placement: 'top-end'
                                }),expression:"{\n                                    content: $t('label.edit')+' '+$t('label.typeMachine'),\n                                    placement: 'top-end'\n                                }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1),_c('ModalTpMachine',{attrs:{"modal":_vm.modalTpMachine,"title":_vm.titleModal,"TpMachineItems":_vm.TpMachineItems,"isEdit":_vm.isEdit},on:{"update:modal":function($event){_vm.modalTpMachine=$event},"Update-list":_vm.Update,"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }